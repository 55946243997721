<template>
    <div>
        <v-rating v-model="localValue" :length="question.configs.maxRating || 5" :rules="rules" color="yellow"
            background-color="yellow darken-3" hover @input="handleInput" :readonly="readonly"></v-rating>
        <div class="caption mt-1" :class="{ 'error--text': hasError }">
            {{ errorMessage || getRatingText }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'RatingQuestionRun',
    props: {
        question: {
            type: Object,
            required: true
        },
        value: {
            type: Number,
            default: 0
        },
        rules: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localValue: this.value,
            errorMessage: '',
            hasError: false
        }
    },
    computed: {
        getRatingText() {
            if (!this.localValue) return 'Selecciona tu valoración';
            if (this.localValue <= 1) return 'Muy malo';
            if (this.localValue <= 2) return 'Malo';
            if (this.localValue <= 3) return 'Regular';
            if (this.localValue <= 4) return 'Bueno';
            return 'Excelente';
        }
    },
    methods: {
        validate() {
            if (this.question.required && !this.localValue) {
                this.errorMessage = 'Este campo es requerido';
                this.hasError = true;
                return false;
            }
            this.errorMessage = '';
            this.hasError = false;
            return true;
        },
        handleInput(val) {
            this.localValue = val;
            this.validate();
            this.$emit('input', val);
        }
    },
    watch: {
        value(newVal) {
            this.localValue = newVal;
        }
    }
}
</script>

<style scoped>
.error--text {
    color: var(--v-error-base) !important;
}
</style>