<template>
    <div>
        <v-checkbox v-model="localValue" :rules="rules" @change="handleInput"
            v-for="(option, index) in question.options" :key="index" :label="option" :value="option"
            :readonly="readonly" :disabled="readonly">
        </v-checkbox>
    </div>
</template>

<script>
export default {
    name: 'CheckboxQuestionRun',
    props: {
        question: {
            type: Object,
            required: true
        },
        value: {
            type: Array,
            default: () => []
        },
        rules: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localValue: Array.isArray(this.value) ? [...this.value] : []
        }
    },
    methods: {
        handleInput(val) {
            this.$emit('input', this.localValue);
        }
    },
    watch: {
        value: {
            handler(newVal) {
                this.localValue = Array.isArray(newVal) ? [...newVal] : [];
            },
            deep: true
        }
    }
}
</script>