<template>
    <div>
        <v-slider disabled :min="1" :max="10" label="Escala máxima" class="mt-4"></v-slider>

        <v-text-field v-model="question.configs.max" label="Escala máxima" class="mt-4"></v-text-field>
        <v-text-field v-model="question.configs.min" label="Escala mínima" class="mt-4"></v-text-field>
    </div>
</template>

<script>
export default {
    name: 'SliderQuestion',
    props: ['question']
}
</script>