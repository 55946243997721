<template>
    <div class="slider-question-run mt-10">
        <v-slider thumb-label="always" tick-size="2" ticks="always" v-model="localValue" :min="question.configs.min"
            :max="question.configs.max" :step="question.configs.step" :readonly="readonly" :rules="rules"
            :error-messages="errorMessages" @change="onChange"></v-slider>
        <div class="d-flex justify-space-between">
            <span class="caption">{{ question.configs.min }}</span>
            <span class="caption">{{ question.configs.max }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SliderQuestionRun',
    props: {
        question: {
            type: Object,
            required: true
        },
        value: {
            type: [Number, String],
            default: null
        },
        rules: {
            type: Array,
            default: () => []
        },
        errorMessages: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localValue: this.parseValue(this.value)
        };
    },
    methods: {
        parseValue(val) {
            if (val === null || val === undefined) return null;
            const num = Number(val);
            return isNaN(num) ? null : num;
        },
        onChange(value) {
            this.$emit('input', value);
        },
        validate() {
            if (this.question.required) {
                return this.localValue !== null && this.localValue !== undefined;
            }
            return true;
        }
    },
    watch: {
        value: {
            handler(newVal) {
                this.localValue = this.parseValue(newVal);
            }
        }
    },
    created() {
        // Initialize with default value if none provided
        if (this.localValue === null) {
            const defaultValue = this.parseValue(this.question.configs.min) || 0;
            this.localValue = defaultValue;
            this.$emit('input', defaultValue);
        }
    }
}
</script>