<template>
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
        min-width="290px" :disabled="readonly">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="localValue" :placeholder="question.configs.placeholder" :rules="rules" outlined dense
                readonly :disabled="readonly" v-bind="attrs" v-on="readonly ? {} : on">
            </v-text-field>
        </template>
        <v-time-picker v-model="localValue" @input="handleInput" :readonly="readonly"></v-time-picker>
    </v-menu>
</template>

<script>
export default {
    name: 'TimeQuestionRun',
    props: {
        question: {
            type: Object,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            menu: false,
            localValue: this.value
        };
    },
    methods: {
        handleInput(val) {
            this.menu = false;
            this.$emit('input', val);
        }
    },
    watch: {
        value(newVal) {
            if (newVal !== this.localValue) {
                this.localValue = newVal;
            }
        }
    }
}
</script>