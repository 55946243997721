<template>
    <v-card class="rounded-lg" elevation="0" disabled>
        <v-card-text class="pa-0">
            <div class="text-center mb-4">
                <v-rating class="mt-3" large :value="value" @input="$emit('input', $event)" color="yellow"
                    background-color="yellow darken-3" hover :rules="rules"></v-rating>
                <div class="caption mt-1 grey--text">
                    {{ getRatingText }}
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'RatingQuestion',
    props: {
        question: {
            type: Object,
            required: true
        },
        value: {
            type: Number,
            default: 0
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        getRatingText() {
            if (!this.value) return 'Selecciona tu valoración';
            if (this.value <= 1) return 'Muy malo';
            if (this.value <= 2) return 'Malo';
            if (this.value <= 3) return 'Regular';
            if (this.value <= 4) return 'Bueno';
            return 'Excelente';
        }
    }
}
</script>