<template>
  <v-container>
    <v-card v-if="!builderDialog" class="rounded-lg overflow-hidden" elevation="1">
      <v-card-title>
        <v-icon left color="primary">
          mdi-file-document-edit-outline
        </v-icon>
        Formularios
        <v-spacer></v-spacer>
        <v-btn class="rounded-lg" color="primary" @click="createNewForm">
          <v-icon left>mdi-plus</v-icon>
          Nueva captura
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-data-table :headers="headers" :items="forms" :search="search" class="elevation-0" :loading="loading">
          <!--   <template v-slot:top>
            <v-text-field v-model="search" label="Buscar" single-line hide-details prepend-inner-icon="mdi-magnify"
              class="mb-2"></v-text-field>
          </template> -->
          <template #[`item.actions`]="{ item }">
            <v-menu bottom left>
              <template #activator="{ on }">
                <v-btn fab small v-on="on" elevation="0">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editForm(item)">
                  <v-list-item-title>
                    <v-icon left>mdi-pencil</v-icon> Editar
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="showResults(item)">
                  <v-list-item-title>
                    <v-icon left>mdi-poll</v-icon> Resultados
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteForm(item)">
                  <v-list-item-title>
                    <v-icon left>mdi-delete</v-icon> Eliminar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template #no-data>
            <v-card class="text-center pa-5 mx-auto my-5" max-width="500" outlined>
              <v-icon size="64" color="grey lighten-1" class="mb-3">
                mdi-file-document-outline
              </v-icon>
              <h3 class="text-h6 grey--text text--darken-1">
                No hay capturas de datos disponibles
              </h3>
              <p class="grey--text text--darken-1 mb-4">
                Comienza creando tu primer captura de datos haciendo clic en el botón "+ Crear captura"
              </p>
              <v-btn class="rounded-lg" color="primary" @click="createNewForm">
                <v-icon left>mdi-plus</v-icon>
                Crear captura
              </v-btn>
            </v-card>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="rounded-lg overflow-hidden" color="transparent" elevation="1" v-else>
      <form-builder v-if="currentView === 'builder'" :selected-form="editSelectedForm" @formSaved="handleFormSaved"
        @formCanceled="handleFormCanceled"></form-builder>
      <form-responses-summary v-else-if="currentView === 'results'" :formId="selectedFormId"
        @back="handleBack"></form-responses-summary>
    </v-card>
  </v-container>
</template>


<script>
import { getFirestore, collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import FormBuilder from './FormBuilder.vue';
import FormResponsesSummary from './FormResponsesSummary.vue';

export default {
  components: {
    FormBuilder,
    FormResponsesSummary
  },
  data() {
    return {
      loading: true,
      forms: [],
      search: '',
      headers: [
        { text: 'Título', value: 'title' },
        { text: 'Cantidad de Secciones', value: 'sectionsCount' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      builderDialog: false,
      editSelectedForm: null,
      currentView: null,
      selectedFormId: null
    };
  },
  async created() {
    await this.fetchForms();
  },
  methods: {
    async fetchForms() {
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, 'forms'));
      this.forms = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          ...data,
          sectionsCount: data.sections ? data.sections.length : 0,
          id: doc.id,
          title: data.sections[0].title
        };
      });

      this.loading = false;
    },
    createNewForm() {
      this.editSelectedForm = null;
      this.currentView = 'builder';
      this.builderDialog = true;
    },
    editForm(item) {
      const formCopy = JSON.parse(JSON.stringify(item));
      this.editSelectedForm = formCopy;
      this.currentView = 'builder';
      this.builderDialog = true;
    },
    async deleteForm(item) {
      if (!confirm('¿Estás seguro de que deseas eliminar este formulario?')) return;
      const db = getFirestore();
      await deleteDoc(doc(db, 'forms', item.id));
      await this.fetchForms();
    },
    async handleFormSaved() {
      // After the builder saves the form, we refresh the table
      await this.fetchForms();
      this.builderDialog = false;
    },
    handleFormCanceled() {
      this.builderDialog = false;
      this.currentView = null;
      this.selectedFormId = null;
    },
    showResults(item) {
      this.selectedFormId = item.id;
      this.currentView = 'results';
      this.builderDialog = true;
    },
    handleBack() {
      this.builderDialog = false;
      this.currentView = null;
      this.selectedFormId = null;
    }
  }
};
</script>

<style scoped></style>
