<template>
    <v-text-field v-model="question.configs.placeholder" label="Placeholder" outlined dense></v-text-field>
</template>

<script>
export default {
    name: 'ShortAnswerQuestionConfig',
    props: {
        question: {
            type: Object,
            required: true
        }
    }
}
</script>