<template>
    <div>
        <v-card class="rounded-lg overflow-hidden" elevation="1">
            <v-card-title class="d-flex align-center">
                <v-btn icon class="mr-2" @click="$emit('back')">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-icon color="primary" class="mr-2">mdi-poll</v-icon>
                <span class="text-h6">Resumen de Respuestas</span>
                <v-spacer></v-spacer>
                <v-chip color="primary" small>
                    {{ responses.length }} Respuestas
                </v-chip>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
                <!-- Date Range Selector -->
                <v-row>
                    <v-col cols="12" sm="5">
                        <v-menu v-model="startDateMenu" :close-on-content-click="false" transition="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dateRange.start" label="Fecha Inicial" readonly v-bind="attrs"
                                    hide-details="" v-on="on" prepend-icon="mdi-calendar" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="dateRange.start" no-title
                                @input="startDateMenu = false"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" sm="5">
                        <v-menu v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dateRange.end" label="Fecha Final" readonly v-bind="attrs"
                                    hide-details="" v-on="on" prepend-icon="mdi-calendar" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="dateRange.end" no-title
                                @input="endDateMenu = false"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" sm="2">
                        <!--                         <div class="text-subtitle-2 mb-2">&nbsp;</div>
 --> <v-btn color="primary" @click="fetchResponses" :loading="loading" block>
                            <v-icon left>mdi-refresh</v-icon>
                            Aplicar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card v-if="form && responses.length > 0" class="mt-4 rounded-lg overflow-hidden" elevation="1">
            <v-card-text>
                <div v-for="section in form.sections" :key="section.id" class="mb-6">
                    <div class="d-flex align-center mb-4">
                        <h3 class="text-h6 mb-0">{{ section.title }}</h3>
                        <v-divider class="mx-4"></v-divider>
                    </div>

                    <div v-for="question in section.questions" :key="question.id" class="mb-8">
                        <v-sheet class="pa-4 rounded-lg" elevation="1">
                            <div class="d-flex align-center mb-4">
                                <h4 class="text-subtitle-1 mb-0">{{ question.text }}</h4>
                                <v-spacer></v-spacer>
                                <v-chip small :color="getQuestionTypeColor(question.type)" text-color="white">
                                    {{ formatQuestionType(question.type) }}
                                </v-chip>
                            </div>

                            <!-- Charts and summaries based on question type -->
                            <div v-if="['multiple_choice', 'checkbox'].includes(question.type)">
                                <apexchart ref="chart" type="pie" height="350" :options="getPieChartOptions(question)"
                                    :series="getPieChartSeries(question)"></apexchart>
                            </div>

                            <div v-else-if="['rating', 'slider'].includes(question.type)">
                                <apexchart ref="chart" type="bar" height="350" :options="getBarChartOptions(question)"
                                    :series="getBarChartSeries(question)"></apexchart>
                            </div>

                            <div v-else-if="['short_answer', 'long_answer'].includes(question.type)">
                                <v-data-table :headers="[
                                    { text: 'Respuesta', value: 'value', width: '70%' },
                                    { text: 'Fecha', value: 'date', width: '30%' }
                                ]" :items="getTextResponses(question)" :items-per-page="5" class="elevation-1">
                                    <template #[`item.date`]="{ item }">
                                        {{ formatDate(item.date) }}
                                    </template>
                                </v-data-table>
                            </div>

                            <div v-else-if="['date', 'time'].includes(question.type)">
                                <v-data-table :headers="[
                                    { text: 'Respuesta', value: 'response' },
                                    { text: 'Cantidad', value: 'count' }
                                ]" :items="getDateTimeResponsesArray(question)" :items-per-page="5"
                                    class="elevation-1"></v-data-table>
                            </div>
                        </v-sheet>
                    </div>
                </div>
            </v-card-text>
        </v-card>

        <v-card v-else-if="!loading && responses.length === 0" class="text-center pa-8 rounded-lg overflow-hidden"
            elevation="1">
            <v-icon size="64" color="grey">mdi-poll-box-outline</v-icon>
            <div class="text-h6 mt-2">Sin Respuestas</div>
            <div class="text-body-2">No hay respuestas en el rango de fechas seleccionado.</div>
            <v-btn color="primary" text @click="fetchResponses" class="mt-4">
                <v-icon left>mdi-refresh</v-icon>
                Intentar de Nuevo
            </v-btn>
        </v-card>
    </div>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import VueApexCharts from 'vue-apexcharts';

export default {
    name: 'FormResponsesSummary',
    components: {
        apexchart: VueApexCharts
    },
    props: {
        formId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            form: null,
            responses: [],
            dateRange: {
                start: this.getDefaultStartDate(),
                end: new Date().toISOString().split('T')[0]
            },
            startDateMenu: false,
            endDateMenu: false
        };
    },
    computed: {
        isDark() {
            return this.$vuetify.theme.dark;
        },
        chartTheme() {
            return {

                mode: this.isDark ? 'dark' : 'light',

                monochrome: {
                    enabled: false,
                    shadeTo: this.isDark ? 'dark' : 'light',
                }
            };
        },
        commonChartOptions() {
            return {

                theme: {
                    mode: this.isDark ? 'dark' : 'light',
                },
            };
        }
    },
    watch: {
        isDark: {
            handler() {
                // Force charts to re-render when theme changes
                this.$nextTick(() => {
                    if (this.$refs.chart) {
                        this.$refs.chart.forEach(chart => {
                            if (chart) {
                                chart.updateOptions(this.getChartOptions(chart.type));
                            }
                        });
                    }
                });
            }
        }
    },
    async created() {
        await this.fetchForm();
        await this.fetchResponses();
    },
    methods: {
        getDefaultStartDate() {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            return date.toISOString().split('T')[0];
        },
        async fetchForm() {
            this.loading = true;
            try {
                const db = getFirestore();
                const formDoc = await getDoc(doc(db, 'forms', this.formId));
                if (formDoc.exists()) {
                    this.form = { id: formDoc.id, ...formDoc.data() };
                    console.log('Form data:', this.form);
                }
            } catch (error) {
                console.error('Error fetching form:', error);
            } finally {
                this.loading = false;
            }
        },
        async fetchResponses() {
            this.loading = true;
            try {
                const db = getFirestore();
                const startDate = new Date(this.dateRange.start);
                startDate.setHours(0, 0, 0);
                const endDate = new Date(this.dateRange.end);
                endDate.setHours(23, 59, 59);

                const q = query(
                    collection(db, 'forms_inputs'),
                    where('formId', '==', this.formId),
                    where('date', '>=', startDate),
                    where('date', '<=', endDate)
                );

                const querySnapshot = await getDocs(q);
                this.responses = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Log the first response in detail
                const firstResponse = this.responses[0];
                console.log('First Response:', {
                    completeObject: firstResponse,
                    data: firstResponse?.data,
                    keys: firstResponse ? Object.keys(firstResponse) : [],
                    dataKeys: firstResponse?.data ? Object.keys(firstResponse.data) : [],
                    nestedData: firstResponse?.data ?
                        Object.entries(firstResponse.data).map(([key, value]) => ({
                            key,
                            value,
                            type: typeof value
                        })) : []
                });

            } catch (error) {
                console.error('Error fetching responses:', error);
            } finally {
                this.loading = false;
            }
        },
        getPieChartOptions(question) {
            const labels = this.getLabels(question);
            return {
                ...this.commonChartOptions,
                chart: {
                    ...this.commonChartOptions.chart,
                    type: 'pie',
                },
                labels: labels,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                legend: {
                    position: 'right',
                    labels: {
                        colors: this.isDark ? ['#fff'] : undefined
                    }
                },
                tooltip: {

                    y: {
                        formatter: function (value) {
                            return value + ' responses';
                        }
                    }
                },
                theme: {
                    mode: this.isDark ? 'dark' : 'light',
                },
            };
        },
        getPieChartSeries(question) {
            const data = this.getQuestionData(question);
            return Object.values(data).length ? Object.values(data) : [1];
        },
        getBarChartOptions(question) {
            const labels = this.getLabels(question);
            return {
                ...this.commonChartOptions,
                chart: {
                    ...this.commonChartOptions.chart,
                    type: 'bar'
                },
                xaxis: {
                    categories: labels.length ? labels : ['No Data'],
                    labels: {
                        style: {
                            colors: this.isDark ? '#fff' : '#373d3f'
                        }
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: this.isDark ? '#fff' : '#373d3f'
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded',
                        distributed: true
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: [this.isDark ? '#fff' : '#373d3f']
                    }
                },
                title: {
                    text: question.text,
                    align: 'center',
                    style: {
                        color: this.isDark ? '#fff' : '#373d3f'
                    }
                },
                tooltip: {
                    theme: this.isDark ? 'dark' : 'light',
                    y: {
                        formatter: function (value) {
                            return value + ' responses';
                        }
                    }
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: [this.isDark ? '#303030' : '#fff']
                },
                theme: {
                    mode: this.isDark ? 'dark' : 'light',
                },
            };
        },
        getBarChartSeries(question) {
            const data = this.getQuestionData(question);
            return [{
                name: 'Responses',
                data: Object.values(data).length ? Object.values(data) : [0]
            }];
        },
        getQuestionData(question) {
            // Find the section that contains this question
            const section = this.form.sections.find(section =>
                section.questions.some(q => q.id === question.id)
            );

            if (!section) {
                return {};
            }

            return this.responses.reduce((acc, response) => {
                // Get value for this response
                const value = response.data?.[section.id]?.[question.id];

                // Skip if no value
                if (value === undefined || value === null) {
                    return acc;
                }

                if (Array.isArray(value)) {
                    value.forEach(v => {
                        if (v !== null && v !== undefined && v !== '') {
                            acc[v] = (acc[v] || 0) + 1;
                        }
                    });
                } else {
                    const strValue = String(value);
                    if (strValue !== '') {
                        acc[strValue] = (acc[strValue] || 0) + 1;
                    }
                }
                return acc;
            }, {});
        },
        getLabels(question) {
            const data = this.getQuestionData(question);
            console.log('Labels for question:', question.text, Object.keys(data));
            return Object.keys(data);
        },
        getTextResponses(question) {
            const section = this.form.sections.find(section =>
                section.questions.some(q => q.id === question.id)
            );

            return this.responses
                .filter(response => response.data?.[section.id]?.[question.id])
                .map(response => ({
                    value: response.data[section.id][question.id],
                    date: response.date instanceof Date ? response.date : response.date.toDate()
                }))
                .sort((a, b) => b.date - a.date);
        },
        getDateTimeResponses(question) {
            const section = this.form.sections.find(section =>
                section.questions.some(q => q.id === question.id)
            );

            return this.responses.reduce((acc, response) => {
                const value = response.data?.[section.id]?.[question.id];
                if (value) {
                    acc[value] = (acc[value] || 0) + 1;
                }
                return acc;
            }, {});
        },
        formatDate(date) {
            try {
                return date instanceof Date ? date.toLocaleString() : new Date(date).toLocaleString();
            } catch (error) {
                console.error('Error formatting date:', error);
                return 'Invalid Date';
            }
        },
        getQuestionTypeColor(type) {
            const colors = {
                multiple_choice: 'indigo',
                checkbox: 'deep-purple',
                rating: 'blue',
                slider: 'cyan',
                short_answer: 'teal',
                long_answer: 'green',
                date: 'amber',
                time: 'orange'
            };
            return colors[type] || 'grey';
        },
        formatQuestionType(type) {
            const translations = {
                'multiple_choice': 'Opción Múltiple',
                'checkbox': 'Casillas',
                'rating': 'Calificación',
                'slider': 'Deslizador',
                'short_answer': 'Respuesta Corta',
                'long_answer': 'Respuesta Larga',
                'date': 'Fecha',
                'time': 'Hora'
            };
            return translations[type] || type.split('_').map(word =>
                word.charAt(0).toUpperCase() + word.slice(1)
            ).join(' ');
        },
        getDateTimeResponsesArray(question) {
            const responses = this.getDateTimeResponses(question);
            return Object.entries(responses).map(([response, count]) => ({
                response,
                count
            }));
        }
    }
};
</script>