<template>
    <v-text-field v-model="localValue" :placeholder="question.configs.placeholder" :rules="rules" outlined dense
        type="number" :readonly="readonly" :disabled="readonly" @input="handleInput">
    </v-text-field>
</template>

<script>
export default {
    name: 'ShortAnswerQuestionRun',
    props: {
        question: {
            type: Object,
            required: true
        },
        value: {
            type: Number,
            default: 0
        },
        rules: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localValue: this.value
        }
    },
    methods: {
        handleInput(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(newVal) {
            this.localValue = newVal;
        }
    }
}
</script>