<template>
    <v-textarea v-model="localValue" :placeholder="question.configs.placeholder" :rules="rules" outlined dense
        :readonly="readonly" :disabled="readonly" @input="handleInput">
    </v-textarea>
</template>

<script>
export default {
    name: 'LongAnswerQuestionRun',
    props: {
        question: {
            type: Object,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localValue: this.value
        }
    },
    methods: {
        handleInput(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(newVal) {
            this.localValue = newVal;
        }
    }
}
</script>